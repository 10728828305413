import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import DispatchTicketDisplay from './dispatch-ticket-display'
import { googleMapsLink } from '../utilities/locationUtilities'
import dayjs from '../utilities/dayjs'

export default function LocationDetailsContainer ({
  dispatchDate,
  users,
  tickets,
  onClose,
  onUpdateTicketFlag,
  onAssignTicketToUser,
  onChangeSite
}) {
  const groupedTickets = useMemo(() => groupBy(tickets, 'driverId'), [tickets])
  const usersById = useMemo(() => fromPairs(map(users, u => [u.id, u])), [users])

  const firstTicket = tickets[0]
  const address = {
    addressline1: firstTicket?.job.addressline1,
    latitude: firstTicket?.job.latitude,
    longitude: firstTicket?.job.longitude,
    state: firstTicket?.job.state,
    city: firstTicket?.job.city,
    zip: firstTicket?.job.zip,
    addressDetails: firstTicket?.job.addressDetails
  }

  return (
    <div>
      <div className='row'>
        <div className='col-xs-12'>
          <div className='pull-left'>
            <button className='btn btn-sm btn-link' onClick={onClose}>
              <i className='material-icons back-button-icon'>arrow_back</i>
            </button>
          </div>
          <h5>
            Tickets on
            &nbsp;
            <strong className='color-brand-dark-gray'>
              {dayjs(dispatchDate).format('MMM DD, YYYY')}
            </strong>
          </h5>
        </div>

        <a className='text-center dispatch-panel panel-no-margin-bottom'
          href={googleMapsLink(address)}
          target='_blank'
          rel='noopener noreferrer'>
          <h6 className='text-primary link'>
            View in Google Maps
          </h6>
        </a>
      </div>

      <div className='row ticket-details-container'>
        <div className='panel panel-info panel-no-margin-bottom'>
          <div className='panel-heading'>
            <div className='container-fluid'>
              <div>
                <h4 className='color-brand-dark-gray float-left'>Tickets</h4>
                <span className='float-right mt-6 badge'>{tickets.length}</span>
              </div>
            </div>
          </div>
          <div className='panel-body'>
            <div id='dispatch-location-tickets-list' className='row'>
              {map(groupedTickets, (driverTickets, driverId) => {
                let driverName
                if (driverId && usersById[driverId] && usersById[driverId].firstName) {
                  const user = usersById[driverId]
                  driverName = `${user.firstName} ${user.lastName}`
                } else {
                  driverName = 'Unassigned'
                }
                return (
                  <div key={driverId}>
                    <h5 className='text-center' style={{ paddingRight: '10px', paddingLeft: '16px' }}>
                      <strong>{driverName}</strong>
                    </h5>

                    {sortBy(driverTickets, ['position', 'id']).map(ticket => (
                      <div
                        className='px-9 pt-9 pb-12 -mx-1 border border-gray-light-10-dark'
                        key={ticket.id}>
                        <DispatchTicketDisplay
                          ticket={ticket}
                          onAssignTicketToUser={onAssignTicketToUser}
                          onUpdateTicketFlag={onUpdateTicketFlag}
                          onChangeSite={onChangeSite}
                        />
                      </div>
                    ))}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LocationDetailsContainer.propTypes = {
  dispatchDate: PropTypes.string.isRequired,
  onUpdateTicketFlag: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    driverId: PropTypes.number
  })).isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape({
    job: PropTypes.shape({
      longitude: PropTypes.number,
      latitude: PropTypes.number,
      addressline1: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string,
      addressDetails: PropTypes.string,
      zip: PropTypes.string
    })
  })),
  onClose: PropTypes.func.isRequired,
  onAssignTicketToUser: PropTypes.func.isRequired,
  onChangeSite: PropTypes.func.isRequired
}
